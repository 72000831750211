import { navigate } from "gatsby"
import axios from "axios"
import { GET_MENU } from "../constants/api"

export const navigateToEsperienza = async ({ esperienza }) => {
  const menu = await axios.get(GET_MENU)

  const trattamento = menu.data.Trattamenti.find(
    tratt => tratt.trattamento === esperienza.trattamento
  )
  const categoria = menu.data.Categorie.find(
    cat => cat.id_sys === trattamento.id_cat
  )

  navigate(
    `/${categoria.macrocategoria.toLowerCase().replace(" ", "-")}/${
      categoria.categoria.toLowerCase()
    }/${(trattamento.nome || trattamento.trattamento).replaceAll(
      " ",
      "-"
    ).toLowerCase()}/esperienze/${esperienza.id_sys}`
  )
}
